.section {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    background-color: #fff;
    animation: animateBg 10s linear infinite;
}

/* @keyframes animateBg {
    0% {
        filter: hue-rotate(0deg);
    }

    100% {
        filter: hue-rotate(360deg);
    }
} */

.section .loader {
    position: relative;
    width: 50px;
    height: 50px;

}

.section .loader span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: rotate(calc(18deg * var(--i)));
}

.section .loader span::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #019267;
    animation: animate 2s linear infinite;
    animation-delay: calc(0.1s * var(--i));
}

@keyframes animate {
    0% {
        transform: scale(1);
    }

    80%,
    100% {
        transform: scale(0);
    }
}