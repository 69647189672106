/* import font */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');


/* import tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;


/* reset */
*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

img {
    display: inline-block;
    width: 100%;
    height: auto;
}

input,
button,
textarea {
    font: inherit;
    font-family: "Inter";
}

input:focus,
button:focus,
textarea:focus,
input:focus-visible,
button:focus-visible,
textarea:focus-visible {
    border-color: var(--PRIMARY-COLOR);
}

input,
textarea {
    outline: 2px solid transparent !important;
    outline-offset: 2px;
}

input:focus,
textarea:focus {
    border-color: var(--PRIMARY-COLOR);
    border-width: 1px;
}

html,
body,
#root {
    min-height: 100%;
    background-color: #FFFFFF;
    font-family: "Inter";
}


/* remove number input arrow */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

/* start checkbox */

/* end checkbox */

/* variable */
:root {
    --PRIMARY-COLOR: #019267;
    --SECONDARY-COLOR: #EC9B3B;
    --LIGHT-SECONDARY-COLOR: #FFF7EE;
    --BLACK-COLOR: #212121;
    --GRAY-COLOR: #475467;
    --LIGHT-GRAY-COLOR: #E5E7EB;
    --INPUT-BACKGROUND-COLOR: #EBEBEB;
    --NEUTRAL-COLOR: #374151;
    --SUBTITLE-COLOR: #6B7280;

}

/* utilities */
.border-secondary {
    border-color: var(--SECONDARY-COLOR);
}

.border-primary {
    border-color: var(--PRIMARY-COLOR);
}

.border-light-gray {
    border-color: var(--LIGHT-GRAY-COLOR);
}

.font-inter {
    font-family: "Inter";
}


.text-primary {
    color: var(--PRIMARY-COLOR);
}

.text-secondary {
    color: var(--SECONDARY-COLOR);
}

.text-black {
    color: var(--BLACK-COLOR);
}

.text-gray {
    color: var(--GRAY-COLOR);
}

.text-neutral {
    color: var(--NEUTRAL-COLOR);
}

.text-subtitle {
    color: var(--SUBTITLE-COLOR);
}

.bg-input {
    background-color: var(--INPUT-BACKGROUND-COLOR);
}

.bg-primary {
    background-color: var(--PRIMARY-COLOR);
}

.bg-secondary {
    background-color: var(--SECONDARY-COLOR);
}

.bg-light-secondary {
    background-color: var(--LIGHT-SECONDARY-COLOR);
}

.exchange-rate-card-shadow {
    box-shadow: 0px 0px 250px rgba(0, 0, 0, 0.32), 0px 1px 4px rgba(0, 101, 242, 0.1);
}

.bg-exchange-rate-card-image {
    background-image: url("../assets/images/Blob.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.bg-phone-image {
    background-image: url("../assets/images/light_Blob.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.max-w-full {
    width: 100%;
}

.main-menu:focus-visible {
    transform: rotateX('90');
}

/* nav */

.nav-hover {
    transition: all .25s ease-in;
}

.nav-hover:hover {
    color: var(--PRIMARY-COLOR);
    /* transform: scale(1.1); */
}

.nav-button-hover {
    transition: all .25s ease-in;
}

.nav-button-hover:hover {
    background-color: var(--SECONDARY-COLOR);
    /* transform: scale(1.1); */
}

.secondary-button-hover {
    transition: all .25s ease-in;
}

.secondary-button-hover:hover {
    color: #FFFFFF;
    background-color: var(--SECONDARY-COLOR);
}

.scale-hover {
    transition: all .25s ease-in;
}

.scale-hover:hover {
    transform: scale(1.05);
}

.svg-hover:hover {
    fill: var(--PRIMARY-COLOR);
}

@media (min-width: 768px) {
    .bg-exchange-rate-card-image {
        background-image: url("../assets/images/Blob_md.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: top;
    }
}


/* animations */
.nav-animate {
    transform-origin: top center;
    animation: openMenu 0.25s ease-in-out forwards;
}

.nav-close-animate {
    transform-origin: top center;
    animation: closeMenu 0.25s ease-in-out forwards;
}

@keyframes openMenu {
    0% {
        transform: scaleY(0);
    }

    80% {
        transform: scaleY(1.2);
    }

    100% {
        transform: scaleY(1);
    }
}

@keyframes closeMenu {
    0% {
        transform: scaleY(1);
    }

    100% {
        transform: scaleY(0);
    }
}

.faq-animate {
    transform-origin: top center;
    animation: openFAQ 0.25s ease-in-out forwards;
}

.faq-close-animate {
    transform-origin: top center;
    animation: closeFAQ 0.25s ease-in-out forwards;
}

.faq-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.25s ease-out;
}

@keyframes openFAQ {
    0% {
        background-color: #FFFFFF;
    }

    100% {
        background-color: #FFF7EE;
    }
}

@keyframes closeFAQ {
    0% {
        background-color: #FFF7EE;
    }

    100% {
        background-color: #FFFFFF;
    }
}

.faq-icon-animate {
    transform-origin: top center;
    animation: showFAQIcon 0.25s ease-in-out forwards;
}

.faq-hide-icon-animate {
    transform-origin: top center;
    animation: hideFAQIcon 0.25s ease-in-out forwards;
}

@keyframes hideFAQIcon {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        display: none;
    }
}

@keyframes showFAQIcon {
    0% {
        opacity: 0;
        display: block;
    }

    100% {
        opacity: 1;
    }
}

/* web app style  */

.mountain-bg {
    background-image: url("../../public/assets/images/mountain.svg");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: auto;
}

@media screen and (max-width: 650px) {
    .mountain-bg {
        background-image: url("../../public/assets/images/mountain.svg");
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: contain;
    }
}

/* body {
    margin: 0 !important;
    font-family: 'DM Sans', sans-serif !important;
    color: #3C3C3C;
} */

input:focus-visible {
    border-color: #019267;
}

/* start font */
.font-recoleta {
    font-family: DM Sans !important;
}

.font-dm-sans {
    font-family: DM Sans !important;
}

/* end font */

/* start color */
.light-dark {
    color: #3C3C3C;
}

.color-placeholder {
    color: #9B9B9B;
}

.color-primary {
    color: #019267;
}

.bg-m-primary {
    background-color: #019267;
}

.bg-primary-light {
    background-color: #DFF1EC;
}

.border-primary {
    border-color: #019267;
}

.bg-form {
    background-color: #FAFAFA;
}

.bg-form1 {
    background-color: #EBEBEB;
}

.border-m-gray {
    border-color: #9B9B9B;
}

.color-backspace {
    color: #989696;
}

/* end color */

/* start shadow */
.shadow-1 {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.hover-shadow-none:hover {
    box-shadow: none !important;
}

.focus-shadow-none:focus {
    box-shadow: none !important;
}


/* end shadow */

/* start sign up page style */
.signup-header {
    font-family: DM Sans !important;
    font-size: 24px !important;
    color: #019267 !important;
    text-align: center !important;
    letter-spacing: -0.03em !important;
    font-style: normal !important;
    font-weight: 700 !important;

}

/* end sign up page style */
/* start checkbox */
input[type="checkbox"]:checked {
    background: #D9D9D9;
    color: #019267;
}

input[type="checkbox"] {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    background: #D9D9D9;
    height: 24px;
    width: 24px;
    border: none;
    color: #019267;
    border-radius: 4px;
}

input[type="checkbox"]:after {
    content: ' ';
    position: relative;
    left: 50%;
    /* top: 20%; */
    bottom: 20%;
    width: 30%;
    height: 110%;
    border: solid #019267;
    border-width: 0 4px 4px 0;
    transform: rotate(50deg);
    display: none;
}

input[type="checkbox"]:checked:after {
    display: block;
}

/* end checkbox */


.ant-modal .ant-modal-content {
    position: relative;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 0;
    border-radius: 8px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    pointer-events: auto;
    padding: 0;
}


.ant-input-textarea-affix-wrapper.ant-input-affix-wrapper>textarea.ant-input {
    font-size: inherit;
    border: none;
    outline: none;
    background-color: #FAFAFA;
}

.ant-input-data-count {
    font-size: 12px !important;
}

.ant-switch.ant-switch-checked:not(.ant-switch-disabled) {
    background: #1b9e72;
}

.ant-switch:not(.ant-switch-disabled) {
    background: #9B9B9B
}

.ant-notification-notice .ant-notification-notice-message {
    margin-bottom: 0px !important;
    color: rgba(0, 0, 0, 0.88);
    font-size: 16px;
    line-height: 1.5;
}


.ant-input-affix-wrapper-focused {
    box-shadow: none !important;
}

.ant-picker-focused {
    box-shadow: none !important;
}

.ant-select-selector {
    width: 100% !important;
    height: auto !important;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    border-radius: 4px !important;
    padding: 0 11px !important;
    --tw-border-opacity: 1 !important;
    border-color: rgb(229 231 235 / var(--tw-border-opacity)) !important;
    outline: none !important;
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    box-shadow: none !important;
    border-width: 2px !important;
    background-color: #FAFAFA !important;
}

.ant-select-selector:hover {
    --tw-border-opacity: 1 !important;
    border-color: rgb(27 158 114 / var(--tw-border-opacity)) !important;
}

.ant-select-selector:focus {
    box-shadow: none !important;
}

@media screen and (min-width: 200px) {
    .ant-modal {
        box-sizing: border-box;
        margin: 0 auto;
        padding: 0;
        color: rgba(0, 0, 0, 0.88);
        font-size: 14px;
        line-height: 1.5714285714285714;
        list-style: none;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
        pointer-events: none;
        position: relative;
        top: 0px;
        width: auto;
        max-width: 100vw;
        padding-bottom: 0px;
    }
}

@media screen and (min-width: 200px) {
    .ant-modal {
        box-sizing: border-box;
        margin: 0 auto;
        padding: 0;
        color: rgba(0, 0, 0, 0.88);
        font-size: 14px;
        line-height: 1.5714285714285714;
        list-style: none;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
        pointer-events: none;
        position: relative;
        top: 100px;
        width: auto;
        max-width: cal(100vw - 32px);
        padding-bottom: 4px !important;
    }
}

  
.prose table {
    width: 100%;
    border-collapse: collapse;
    margin: 1rem 0;
    font-size: 0.95rem;
  }
  
  .prose th,
  .prose td {
    border: 1px solid var(--SECONDARY-COLOR);
    padding: 12px 15px;
    text-align: left;
  }
  
  .prose th {
    background-color: #f9f9f9;
    font-weight: bold;
    color: #333;
  }
  
  .prose tr:nth-child(even) {
    background-color: #f8f8f8;
  }
  
  .prose tr:hover {
    background-color: var(--SECONDARY-COLOR);
  }
  
  .prose table {
    border: 1px solid var(--SECONDARY-COLOR);
    border-radius: 8px;
    overflow: hidden;
  }